import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ExpertisesMainSection } from 'views/expertises/expertises-main-section';
import { ServicesSection } from 'views/expertises/services-section';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { RequestSection } from 'modules/request-section';
import { WorkingWithUsSection } from 'modules/working-with-us';
import { AIAssistantSection } from 'modules/ai-assistant-section';
const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

const LINK_CHUNKS = {
    TEAM: 'team of experts',
    SOLUTION: 'we provide a solution',
};

const resolveTo = (chunk) => {
    if (chunk === LINK_CHUNKS.TEAM) {
        return PATHS.TEAM;
    }

    return PATHS.SOFTWARE_DEVELOPMENT_PROCESS;
};

export const ExpertisesView = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <ExpertisesMainSection />
            <AIAssistantSection />
            <ServicesSection />
            <QuoteBannerSection
                quote="quotes.mobileRealityCompany"
                author="quotes.justynaSznajder"
                position="quotes.productManager"
            />
            <WorkingWithUsSection
                title={formatMessage({
                    id: 'expertises.workingWithUs',
                })}
                alt="expertises.imgAlts.workingWithTeam"
                descriptions={[
                    formatMessage(
                        {
                            id: 'expertises.workingWithUsInfo1',
                        },
                        {
                            link: (chunks) => (
                                <SPrefixLangLink to={resolveTo(chunks[0])}>
                                    {chunks}
                                </SPrefixLangLink>
                            ),
                        },
                    ),
                    formatMessage({
                        id: 'expertises.workingWithUsInfo2',
                    }),
                ]}
            />
            <RequestSection />
        </>
    );
};
